// v1.0.1 02-12-2024
"use client";

// React
import React, { useEffect, useState } from "react";

// Layout
import Footer from "@/components/ui/footer";
import SidebarMenu from "@/components/SidebarMenu";
import Navbar from "@/components/ui/Navbar";

// pathname
import { usePathname } from "next/navigation";

// Authentification
import logout, { setAutoLogout } from "@/lib/Logout";
import cookie from "js-cookie";

// i18n
import { useLocale } from "next-intl";

// Context
import GetTenant from "@/lib/GetTenant";
import { GetEnvironment } from "@/lib/GetEnvironment";

// Toasts
import { Toaster } from "sonner";

type LayoutClientProps = {
  children: React.ReactNode;
};

const regexPagesWithoutLayout: RegExp[] = [];
const pagesNotNeedingAuth = ["support", "status"];
const LayoutClient: React.FC<LayoutClientProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLayout, setShowLayout] = useState(true);
  const [isIframe, setIsIframe] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageDoesntNeedsAuth, setPageDoesntNeedsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const locale = useLocale();
  const pathName = usePathname();

  // Define the message handler function
  const handleMessage = (message: MessageEvent) => {
    console.log("message", message);
    const originList = message.origin.split(".");
    // Check the origin for security
    if (
      (originList[originList.length - 3] !== "login" &&
        originList[originList.length - 2] !== "phpr" &&
        originList[originList.length - 1] !== "link") ||
      message.origin.indexOf("localhost") !== -1
    ) {
      console.error("Message received from invalid origin:", message.origin);
      return;
    }

    try {
      // Parse the message data safely
      const data = JSON.parse(message.data);
      const token = data.token;

      // If userId is present, call the function to handle the data
      if (token) {
        cookie.set("currentUser", token, {
          expires: 1 / 24,
        });
        setAutoLogout(token);
      }
    } catch (error) {
      console.error("Error parsing message data:", error);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Add the event listener
    if (window.self !== window.top) {
      const tenant = GetTenant();
      const environment =
        GetEnvironment(window.location.href) === "prod"
          ? "."
          : `.${GetEnvironment(window.location.href)}.`;

      window.addEventListener("message", handleMessage);

      window.parent.postMessage(
        JSON.stringify({
          status: "ready",
          id: process.env.NEXT_PUBLIC_APP_ID || "punch",
        }), // Replace with your app's ID in the .env file
        `https://${tenant}${environment}${
          process.env.NEXT_PUBLIC_AUTH_DOMAIN || "login.phpr.link"
        }` // Replace with your login app's origin
      );

      setIsIframe(true);
    } else {
      const token = cookie.get("currentUser");
      if (token) {
        setAutoLogout(token);
        setIsAuthenticated(true);
      } else {
        console.log("no token");
        logout(locale);
      }
    }

    setLoading(false);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (window.self !== window.top) {
        window.removeEventListener("message", handleMessage);
      }
    };
  }, []);

  useEffect(() => {
    console.log("LayoutClient useEffect running on pathName change", pathName);
    if (regexPagesWithoutLayout.some((regex) => regex.test(pathName))) {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }

    if (pagesNotNeedingAuth.some((page) => pathName.includes(page))) {
      setPageDoesntNeedsAuth(true);
    }

    const token = cookie.get("currentUser");
    if (!token) {
      logout(locale);
    }
  }, [pathName]);

  if ((!pageDoesntNeedsAuth && !isAuthenticated) || loading || isIframe) {
    return null;
  }

  return (
    <>
      {!showLayout ? (
        children
      ) : (
        <main className="flex min-h-full h-max dark:bg-dark-mode">
          <SidebarMenu isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
          <section className="flex flex-col min-h-screen w-full justify-between">
            <Navbar
              fqcn_bui={{
                Bundle: `${process.env.NEXT_PUBLIC_APP_ID}Bundle`,
                Unit: "navbar",
                Interface: "pageHeader",
              }}
            />
            <div className="flex flex-col items-center h-full mb-56 md:mb-40">
              {children}
            </div>
            <Toaster />
            <Footer
              fqcn_bui={{
                Bundle: `${process.env.NEXT_PUBLIC_APP_ID}Bundle`,
                Unit: "footer",
                Interface: "pageFooter",
              }}
            />
          </section>
        </main>
      )}
    </>
  );
};

export default LayoutClient;
